<template>
  <div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <section
            class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3"
          >
            <div class="card">
              <div class="card-body table-responsive table-head">
                <div class="row bor-bot pb-3">
                  <div class="col-md-8 pl-0">
                    <h1 class="m-0 text-dark pt-2 pb-2">Safe Sport Import</h1>
                  </div>
                  <div class="col-md-4">
                    <div class="text-right pb-3">
                      <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                          <a href="/admin/dashboard">Home</a>
                        </li>
                        <li class="breadcrumb-item"><Breadcrumbs /></li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-7">
                    <div class="row">
                      <custom-alert
                        v-if="displayAlert == true"
                        :message="alertMessage"
                        v-on:showedAlert="resetAlert"
                      ></custom-alert>
                      <div class="col-md-9">
                        <div class="form-group">
                          <label for="upload_csv" class="control-label"
                            ><b>Upload Excel File</b></label
                          ><br />
                          <input
                            type="file"
                            name="upload_csv"
                            id="upload_csv"
                            accept=".xls,.xlsx"
                            @change="fileChoosed"
                            ref="file"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="btn btn-primary darkblubtn mt-3"
                          @click="uploadCSV"
                          v-if="file"
                          :disabled="loading"
                        >
                          <i class="fas fa-upload"></i> Upload
                        </button>
                      </div>
                    </div>
                    <div class="row mt-3" v-if="results.skipped">
                      <div class="col-md-12">
                        <strong>Result:</strong>
                        <div class="result-wrap">
                          <p class="text-success"><strong>{{ results.success }} rows are successfully updated</strong></p>
                          <p class="text-danger"><strong>{{ results.skipped.length }} rows are skipped</strong></p>
                          <ul>
                            <li v-for="(item, k) in results.skipped" :key="'result-'+k">
                              <strong>{{ item.member_id }}</strong> - {{ item.message | titlecase }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-md-12 mb-3">
                        <p>You can download and view the sample format of excel which you have to use to import</p>
                        <button class="btn btn-primary darkblubtn" type="button" @click="downloadSample">
                          <i class="fa fa-download"></i> Download Template
                        </button>
                      </div>
                      <div class="col-md-12">
                        <p><b>Status Codes</b></p>
                      </div>
                      <div class="col-md-8">
                        <table class="table table-sm text-center">
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0</td>
                              <td>Not Started</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>In Progress</td>
                            </tr>
                            <!-- <tr>
                              <td>2</td>
                              <td>Pending Approval</td>
                            </tr> -->
                            <tr>
                              <td>3</td>
                              <td>Completed</td>
                            </tr>
                            <!-- <tr>
                              <td>4</td>
                              <td>Not Completed</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Failed</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Declined</td>
                            </tr> -->
                            <tr>
                              <td>13</td>
                              <td>Expired</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- <div class="col-md-6">
                        <table class="table table-sm text-center">
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>7</td>
                              <td>Pending Evaluation Required</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>On Waitlist</td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>Absent</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>Not Applicable</td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td>Pending Proctor</td>
                            </tr>
                            <tr>
                              <td>12</td>
                              <td>Ready For Review</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import CustomAlert from "../../CustomAlert.vue";
export default {
    components: { CustomAlert },
    data(){
        return {
            file: null,
            loading: false,
            displayAlert: false,
            alertMessage: "Safe Sport Statuses Imported",
            results: []
        };
    },
    methods: {
        fileChoosed(event){
            if(event.target.files.length) this.file = this.$refs.file.files[0];
            else this.file = null;
        },
        uploadCSV(event){
            let formData = new FormData();
            formData.append('file', this.file);

            this.loading = true;
            axios.post(this.basePath+"api/import-csv/safesports", formData, {
                headers: {
                    "Authorization":"Bearer "+localStorage.getItem('admintoken'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.loading = false;
                this.displayAlert = true;
                 this.results = res.data.data;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        downloadSample(event){
          axios.get(
            this.basePath+"api/import-csv/download-sample?sample=safe_sport", 
            { 
              headers:  {
                "Authorization":"Bearer "+localStorage.getItem('admintoken'),
                'Content-Disposition': "attachment; filename=safesport-statuses.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              },
              responseType: 'arraybuffer',
            }
          )
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'safesport-statuses.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            console.log(err);
          })
        }
    },
    filters: {
      titlecase: function(value){
        if (!value) return ''

        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    }
}
</script>

<style scoped>
  .result-wrap{
    background: #faf4f4;
    padding: 15px;
  }
  .result-wrap ul {
    margin-bottom: 0;
    list-style-type: disclosure-closed;
    line-height: 35px;
  }
</style>